/* eslint-disable no-new */

import SmarteditController from '@/utils/Controllers/SmarteditController';

export default () => {
  const BindStickyStripe = () => {
    const ACTIVE_CLASS = '--active';
    const STICKY_SECTION_ID = 'js-del-start-sticky-stripe';
    const STICKY_WRAPPER_SELECTOR = '.js-del-stripe__sticky';

    const observerConfig = { rootMargin: '0px 0px 600px 0px' };

    const observeStickyInteraction = () => {
      const observerFn = entries => {
        const el = document.querySelector(STICKY_WRAPPER_SELECTOR);
        const { isIntersecting, boundingClientRect } = entries[0];
        const method =
          !isIntersecting && boundingClientRect.top < 0 ? 'add' : 'remove';
        el.classList[method](ACTIVE_CLASS);
      };
      const stickySectionEl = document.getElementById(STICKY_SECTION_ID);
      const observer = new IntersectionObserver(observerFn, observerConfig);
      observer.observe(stickySectionEl);
    };

    const wrapperSelector = document.querySelector(STICKY_WRAPPER_SELECTOR);
    if (wrapperSelector) observeStickyInteraction();
  };
  SmarteditController.addOnReprocessPageListener(BindStickyStripe);
  BindStickyStripe();
};
